import React from 'react'
import MetaImage from '../../assets/images/story/truelayer/truelayer-cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import TrueLayerBody from '../../components/story/truelayer/Content'
import TrueLayerHeader from '../../components/story/truelayer/Header'

const TrueLayerPage = () => {
  return (
    <Layout
      title="TrueLayer case study"
      description="The product engineering organization at TrueLayer uses Swarmia to gain visibility into engineering investment and empower teams to improve their productivity.
      "
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <TrueLayerHeader />
        <QuoteBlock person="maryanna" />
        <TrueLayerBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best software teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default TrueLayerPage
